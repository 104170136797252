.conteudo {
  background-color: white;
}

:root {
  --primaria: #58a4b0;
  --secundaria: #373f51;
  --secundaria-ativa: #cccccc;
  --fundo: #ffffff;
  --fundo-detalhes: #ececec;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
  -webkit-appearance: textfield; /* 1 */ /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.footer {
  margin-top: 5.5%;
  background-color: white;
  box-shadow: 0px -5px 5px 0px rgba(80, 80, 80, 0.5);
}

.copyright {
  font-weight: bold;
  font-size: 1.25em;
  text-align: center;
  padding: 1em;
  color: black;
  max-width: 100%;
}

.navbar-itens {
  z-index: 5;
  background-color: rgb(255, 255, 255);
  height: 80px;
  width: 100%;
  box-shadow: 0px 5px 5px 0px rgba(80, 80, 80, 0.5);
}

.navbar-menu {
  position: absolute;
  top: 0px;
  right: 10vw;
  z-index: 9999;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: inline-block;
  vertical-align: middle;
}

.navbar-menu li {
  width: 120px;
  height: 80px;
  font-size: 1.2em;
  background-color: none;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.navbar-menu .navbar-apresentacoes {
  font-size: 0.9em;
  padding: 26pt 0 7pt 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-decoration: none;
  color: black;
  transition: 0.5s;
}

.navbar-menu a {
  font-size: 1em;
  padding: 24pt 0 20pt 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-decoration: none;
  color: black;
  transition: 0.5s;
}

/* .navbar-menu .navbar-mostrar a {
  background: white;
  display: flex;
  height: 50px;
  width: 120px;
  padding: 0 3px 0 3px;
  overflow: hidden;
  font-size: 16px;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(80, 80, 80, 0.5);
  -moz-box-shadow: 0px 5px 5px 0px rgba(80, 80, 80, 0.5);
  box-shadow: 0px 5px 5px 0px rgba(80, 80, 80, 0.5);
} */

.navbar-menu a:hover {
  background: rgb(230, 230, 230);
}

/* .navbar-menu .navbar-mostrar {
  display: none;
  position: absolute;
} */

/* .navbar-mostrar li {
  height: 80px;
  width: 120px;
  font-size: 20px;
  background-color: none;
} */

/* .navbar-produtos:hover .navbar-mostrar {
  display: block;
} */

/* .navbar-servicos:hover .navbar-mostrar {
  display: block;
} */

.navbar-itens .navbar-menu li {
  display: inline-block;
  list-style-type: none;
  text-align: center;
}

/* .navbar-itens .navbar-menu .navbar-mostrar li {
  display: inline;
} */

.navbar-home {
  position: absolute;
  top: 0vw;
  left: 0vw;
}

.navbar-logo {
  position: absolute;
  height: 70px;
  left: 6vw;
}

@media (max-width: 1200px) {
  .navbar-menu {
    display: none;
  }

  .navbar-home {
    position: relative;
    left: 20%;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50vw;
  }

  .navbar-logo {
    position: absolute;
    height: 80px;
    width: inherit;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .navbar-mobile {
    position: fixed;
    z-index: 3;
    top: 0;
    left: -90%;

    transition: left 0.3s ease-out;
  }

  .navbar-mobileAtivo {
    max-width: 300px;
    left: 0;
    background: white;
    padding: 1em;
    margin: 0;

    height: 100%;
    width: 90%;

    position: fixed;
    z-index: 6;
    top: 0;

    transition: left 0.3s ease-out;
  }

  .navbar-mobilebg:after {
    z-index: 5;
    content: "";
    display: block;

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background: rgba(0, 0, 0, 0.4);
  }

  .navbar-mobileAtivo .navbar-menu {
    display: block;
  }

  .navbar-menu {
    margin: 3em 1em;
  }
}

@media not all and (max-width: 1200px) {
  .menu-abrir,
  .menu-fechar {
    display: none;
  }
}

.menu-abrir,
.menu-fechar {
  background: none;
  border: 0;
  outline: 0;
  -webkit-appearance: none;
  font-size: 2em;
  text-indent: -999em;
}

.menu-abrir {
  position: absolute;
  height: 0;
  width: 0.75em;
  padding-top: 0.125em;
  border-top: 0.375em double #000;
  border-bottom: 0.125em solid #000;
  left: 0.5vw;
  top: 1.5vh;
  bottom: 0;

  vertical-align: middle;
  margin: 0.5em;
}

.menu-fechar {
  position: relative;
  height: 1em;
  width: 1em;
}
.menu-fechar:before {
  content: "\00D7";
  position: absolute;
  top: 0;
  left: 0;
  text-indent: 0;
}

@media (max-width: 600px) {
  .navbar-home {
    left: 27%;
  }
}

.apresentacoes-slider .image-gallery-slide .image-gallery-image {
  object-fit: fill;
}

.apresentacoes-slider {
  padding: 5% 0;
  box-shadow: 0px 3px 7px 0px rgba(50, 50, 50, 0.5);
  float: none;
}

.apresentacoes-titulo {
  font-size: 2.2em;
  margin-bottom: 2%;
  padding: 1% 0;
  font-weight: bold;
  text-align: center;
  box-shadow: 0px -3px 7px 0px rgba(50, 50, 50, 0.5);
  margin-bottom: 3%;
}

.slider-desenhos {
  width: 60%;
  margin-left: 20%;
}

.slider-apresentacao {
  width: 60%;
  margin-left: 20%;
  margin-top: 5%;
}

@media (max-width: 1000px) {
  .slider-desenhos {
    width: 100%;
    padding-bottom: 4%;
    margin-left: 0;
  }

  .slider-apresentacao {
    width: 100%;
    padding-left: 0;
    margin-left: 0;
  }
}

#facial {
  padding: 5% 0 8vh 0;
  box-shadow: 0px 3px 7px 0px rgba(50, 50, 50, 0.5);
}

.facial-titulo {
  font-size: 2.2em;
  margin-bottom: 2%;
  padding: 1% 0;
  font-weight: bold;
  text-align: center;
  box-shadow: 0px -3px 7px 0px rgba(50, 50, 50, 0.5);
}

#facial ul {
  padding: 2vh;
  width: 80%;
  margin-left: 10%;
}

#facial li {
  width: 90%;
  margin-left: 5%;
  display: inline-block;
  margin-bottom: 0.3em;
  margin-top: 1.5%;
}

#facial li p {
  margin: 1% 3.5% 0 3.5%;
  text-align: justify;
  font-size: 1.5em;
}

.negrito {
  font-weight: bold;
}

.facial-lista {
  padding: 1em 5vw 5vh 5vw;
  font-size: 1.2em;
}

.conPessoas.facial li {
  border: none;
}
.fale-conosco {
  padding: 5% 10% 3% 15%;
  margin-top: 5%;
  box-shadow: 0px 0px 13px 0px rgba(50, 50, 50, 0.8);
}

.input-padrao {
  margin: 0 0 1.25em;
  padding: 0.5em 1em;
  width: 35%;
  font-size: 0.9em;
}

.input-padraoMensagem {
  margin: 0 0 1.25em;
  padding: 0.5em 1em;
  width: 35%;
}

.fale-secao {
  width: 47%;
  float: left;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.fale-mensagem {
  text-align: center;
  margin-right: 5.6%;
}

.fale-conosco fieldset {
  text-align: center;
  margin-right: 5.6%;
}

.fale-conosco .fale-radio {
  margin-left: 38%;
  text-align: justify;
}

.fale-conosco form label,
form legend {
  display: block;
  font-size: 1.25em;
  margin: 0 0 0.7em;
}

.fale-conosco .enviar {
  width: 25%;
  padding: 0.9em 0;
  background: rgb(98, 179, 255);
  color: white;
  font-weight: bold;
  font-size: 90%;
  border: none;
  border-radius: 0.5em;
  transition: 1s all;
  cursor: pointer;
  margin: 2vh 0 3vh 34%;
  max-width: 30%;
}

.fale-conosco .enviar:hover {
  background: rgb(0, 130, 190);
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

/* .fale-Whats {
  height: 2.5em;
  margin-bottom: 1em;
  margin-left: 37%;
}

.fale-whats {
  width: 10em;
  background: #15c546;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  text-decoration: none;
  border-radius: 0.5em;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fale-whatsIcon {
  padding: 0 5%;
}

@media (max-width: 1024px) {
  .fale-Whats {
    margin-left: 30%;
  }
} */

@media (max-width: 800px) {
  .fale-secao {
    width: 100%;
    float: left;
  }

  .fale-conosco .enviar {
    min-width: 50%;
    margin: 2vh 0 0 22%;
  }

  .fale-mensagem {
    margin-left: 0;
    margin-right: 0;
  }

  .fale-conosco fieldset {
    margin-left: 0;
  }

  .fale-conosco .fale-radio {
    margin-left: 30%;
  }

  /* .fale-Whats {
    height: 2em;
    margin-top: 1em;
    margin-left: 10%;
  }

  .fale-whats {
    width: 8.5em;
    background: #15c546;
    color: white;
    font-weight: bold;
    font-size: 1em;
    text-decoration: none;
    border-radius: 0.5em;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fale-whatsIcon {
    padding: 0 3%;
  } */
}

.image-gallery-image {
  height: 55vh;
}

.div-video {
  margin-top: 5%;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 3%;
  padding-right: 3%;
  box-shadow: 0px 0px 13px 0px rgba(50, 50, 50, 0.8);
}

.slide-home {
  width: 70%;
  margin-left: 15%;
}

@media (max-width: 700px) {
  .slide-home {
    width: 100%;
    margin-left: 0;
  }
}

.div-clientes {
  margin-top: 5%;
  box-shadow: 0px 0px 13px 0px rgba(50, 50, 50, 0.8);
  padding-bottom: 5%;
}

.titulo-clientes {
  font-size: 2.5em;
  text-align: center;
  font-weight: bold;
  padding-top: 1.5%;
}

.lista-clientes {
  margin-top: 3%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  margin-left: 2%;
  margin-right: 2%;
}

.clientes {
  display: inline-block;
  width: 200px;
  height: 100px;
  margin: 0 2.5% 0 2.5%;
}

.camera-texto {
  font-size: 110%;
  margin-top: 0.5em;
}

.cliente-image-poly,
.cliente-image-porto,
.cliente-image-camera,
.cliente-image-logibras,
.cliente-image-suzano {
  width: 200px;
  height: 100px;
}

.lista-clientes2 {
  display: none;
}

@media (max-width: 1280px) {
  .clientes {
    display: inline-block;
    width: 200px;
    height: 100px;
    margin: 3% 1.5% 0 1.5%;
  }
}

@media (max-width: 1160px) {
  .lista-clientes {
    display: none;
  }

  .lista-clientes2 {
    margin-top: 3%;
    display: inline-block;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    vertical-align: middle;
    margin-left: 25%;
    margin-right: 25%;
    width: 50%;
  }
}

@media (max-width: 870px) {
  .lista-clientes2 {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }
}

@media (max-width: 620px) {
  .lista-clientes2 {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}

.home-page .image-gallery-slide .image-gallery-image {
  object-fit: fill;
}

.home-slider {
  box-shadow: 0px 5px 9px 0px rgba(50, 50, 50, 0.6);
}

.conPessoas-tituloPrinc {
  font-size: 1.56em;
  text-align: center;
  font-weight: bold;
  margin-bottom: 3vh;
  padding: 0.5% 0;
  background-color: rgb(241, 241, 241);
}

.conPessoas ul {
  padding: 2vh;
  width: 80%;
  margin-left: 10%;
}

.conPessoas li {
  width: 90%;
  margin-left: 5%;
  display: inline-block;
  margin-bottom: 0.3em;
  border-bottom: 1px solid black;
}

.conPessoas-tituloProd {
  font-size: 1.25em;
  text-align: center;
  font-weight: bold;
  padding: 2vh;
}

.conPessoas .conPessoas-texto-image {
  margin: 2.7% 40% 0 4%;
  font-size: 1.25em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: justify;
}

.conPessoas li p {
  margin: 2.7% 4% 5% 4%;
  font-size: 1.25em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: justify;
}

.conPessoas .image-gallery {
  width: 30%;
  float: right;
  margin: 3vh 3vw 4vh 0;
}

.conPessoas .image-gallery .image-gallery-icon {
  height: 10px;
  width: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.conPessoas .image-gallery .image-gallery-svg {
  width: 60px;
  height: 60px;
}

@media (max-width: 950px) {
  .conPessoas li {
    width: 90%;
    margin-left: 5%;
    margin-bottom: 0.3em;
    border-bottom: 1px solid black;
  }

  .conPessoas-tituloProd {
    font-size: 1.25em;
    text-align: center;
    font-weight: bold;
    padding: 2vh;
  }

  .conPessoas li p {
    margin: 2% 5% 3% 5%;
    font-size: 1.25em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: justify;
  }

  .conPessoas .image-gallery {
    width: 50%;
    float: none;
    margin-bottom: 0;
    margin-left: 25%;
  }
}

@media (max-width: 770px) {
  .conPessoas li p {
    margin: 2% 5% 3% 5%;
    font-size: 1.25em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: justify;
  }

  .conPessoas .image-gallery {
    width: 70%;
    float: none;
    margin-bottom: 0;
    margin-left: 15%;
  }

  .conPessoas .image-gallery .image-gallery-play-button {
    height: 60px;
    width: 50px;
  }

  .conPessoas .image-gallery .image-gallery-fullscreen-button {
    height: 60px;
    width: 50px;
  }
}

@media (max-width: 600px) {
  .conPessoas li p {
    margin: 2% 5% 3% 5%;
    font-size: 1.25em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: justify;
  }

  .conPessoas .image-gallery {
    width: 90%;
    float: none;
    margin-bottom: 0;
    margin-left: 5%;
  }
}

.conVeiculos-tituloPrinc {
  font-size: 1.56em;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0.5em;
  margin-top: 1em;
  padding: 0.5% 0;
  background-color: rgb(241, 241, 241);
}

.conVeiculos ul {
  padding: 2vh;
  width: 80%;
  margin-left: 10%;
}

.conVeiculos li {
  width: 90%;
  margin-left: 5%;
  display: inline-block;
  margin-bottom: 0.3em;
  border-bottom: 1px solid black;
}

.conVeiculos-tituloProd {
  font-size: 1.25em;
  text-align: center;
  font-weight: bold;
  padding: 2vh;
}

.conVeiculos li p {
  margin: 2.7% 4% 5% 4%;
  font-size: 1.25em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: justify;
}

.conVeiculos .conVeiculos-texto-image {
  margin: 2.7% 40% 0 4%;
  font-size: 1.25em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: justify;
}

.conVeiculos .image-gallery {
  width: 30%;
  float: right;
  margin: 3vh 3vw 4vh 0;
}

.conVeiculos .image-gallery .image-gallery-icon {
  height: 10px;
  width: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.conVeiculos .image-gallery .image-gallery-svg {
  width: 60px;
  height: 60px;
}

@media (max-width: 950px) {
  .conVeiculos li p {
    margin: 2% 5% 3% 5%;
    font-size: 1.25em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: justify;
  }

  .conVeiculos .image-gallery {
    width: 50%;
    float: none;
    margin-bottom: 0;
    margin-left: 25%;
  }
}

@media (max-width: 770px) {
  .conVeiculos li p {
    margin: 2% 5% 3% 5%;
    font-size: 1.25em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: justify;
  }

  .conVeiculos .image-gallery {
    width: 70%;
    float: none;
    margin-bottom: 0;
    margin-left: 15%;
  }

  .conVeiculos .image-gallery .image-gallery-play-button {
    height: 60px;
    width: 50px;
  }
}

@media (max-width: 600px) {
  .conVeiculos li p {
    margin: 2% 5% 3% 5%;
    font-size: 1.25em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: justify;
  }

  .conVeiculos .image-gallery {
    width: 90%;
    float: none;
    margin-bottom: 0;
    margin-left: 5%;
  }
}

.conAcesso-titulo {
  font-size: 2.2em;
  margin-bottom: 2%;
  padding: 1% 0;
  font-weight: bold;
  text-align: center;
  box-shadow: 0px -3px 7px 0px rgba(50, 50, 50, 0.5);
}

#controleAcesso {
  padding: 5% 0 8vh 0;
  box-shadow: 0px 3px 7px 0px rgba(50, 50, 50, 0.5);
}

.autoGates-tituloPrinc {
  font-size: 1.56em;
  text-align: center;
  font-weight: bold;
  margin-bottom: 3vh;
  padding: 0.5% 0;
  background-color: rgb(241, 241, 241);
}

.autoGates ul {
  padding: 2vh;
  width: 80%;
  margin-left: 10%;
}

.autoGates li {
  width: 90%;
  margin-left: 5%;
  display: inline-block;
  margin-bottom: 0.3em;
  border-bottom: 1px solid black;
}

.autoGates-tituloProd {
  font-size: 1.25em;
  text-align: center;
  font-weight: bold;
  padding: 2vh;
}

.autoGates li p {
  margin: 2.7% 4% 5% 4%;
  font-size: 1.25em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: justify;
}

.autoGates .autoGates-texto-image {
  margin: 2.7% 40% 0 4%;
  font-size: 1.25em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: justify;
}

.autoGates .image-gallery {
  width: 30%;
  float: right;
  margin: 3vh 3vw 4vh 0;
}

.autoGates .image-gallery .image-gallery-icon {
  height: 10px;
  width: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.autoGates .image-gallery .image-gallery-svg {
  width: 60px;
  height: 60px;
}

@media (max-width: 950px) {
  .autoGates li p {
    margin: 2% 5% 3% 5%;
    font-size: 1.25em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: justify;
  }

  .autoGates .image-gallery {
    width: 50%;
    float: none;
    margin-bottom: 0;
    margin-left: 25%;
  }
}

@media (max-width: 770px) {
  .autoGates .image-gallery {
    width: 70%;
    float: none;
    margin-bottom: 0;
    margin-left: 15%;
  }

  .autoGates .image-gallery .image-gallery-play-button {
    height: 60px;
    width: 50px;
  }
}

@media (max-width: 600px) {
  .autoGates .image-gallery {
    width: 90%;
    float: none;
    margin-bottom: 0;
    margin-left: 5%;
  }
}

.ocr-lpr-titulo {
  font-size: 2.2em;
  margin-bottom: 2%;
  padding: 1% 0;
  font-weight: bold;
  text-align: center;
  box-shadow: 0px -3px 7px 0px rgba(50, 50, 50, 0.5);
}

#ocr-lpr {
  padding: 5% 0 8vh 0;
  box-shadow: 0px 3px 7px 0px rgba(50, 50, 50, 0.5);
}

#servicos {
  padding: 5% 0 8vh 0;
  box-shadow: 0px 3px 7px 0px rgba(50, 50, 50, 0.5);
}

.servicos-titulo {
  font-size: 2.2em;
  margin-bottom: 2%;
  padding: 1% 0;
  font-weight: bold;
  text-align: center;
  box-shadow: 0px -3px 7px 0px rgba(50, 50, 50, 0.5);
}

#servicos ul {
  padding: 2vh;
  width: 80%;
  margin-left: 10%;
}

#servicos li {
  width: 90%;
  margin-left: 5%;
  display: inline-block;
  margin-bottom: 0.3em;
  margin-top: 1.5%;
}

#servicos li p {
  margin: 1% 3.5% 0 3.5%;
  text-align: justify;
  font-size: 1.5em;
}

.negrito {
  font-weight: bold;
}

.sobre {
  padding: 5% 0;
  box-shadow: 0px 3px 7px 0px rgba(50, 50, 50, 0.5);
}

.sobre-titulo {
  font-size: 2.2em;
  margin-bottom: 2%;
  padding: 1% 0;
  font-weight: bold;
  text-align: center;
  box-shadow: 0px -3px 7px 0px rgba(50, 50, 50, 0.5);
}

.sobre-texto {
  font-size: 1.5em;
  text-align: justify;
  padding: 3% 5% 3% 5%;
}

.sobre-image {
  margin-left: 5%;
  max-width: 1980px;
  width: 90%;
}

.sobre-lista {
  padding: 1em 5vw 0 5vw;
  font-size: 0.8em;
}

.sobre-tabela {
  margin: 0 auto;
  padding: 50px 0;
  text-align: center;
}

.sobre-titulo2 {
  font-size: 1.75em;
  text-align: center;
}

.sobre-tabela p {
  font-size: 1.1em;
  text-align: justify;
  padding: 5% 5% 3% 5%;
}

.sobre-tabela li {
  display: inline-block;
  text-align: center;
  width: 25%;
  vertical-align: top;
  margin: 0 1.5%;
  padding: 30px 20px;
}

@media (max-width: 1020px) {
  .sobre-tabela li {
    width: 90%;
  }
}

