.sobre {
  padding: 5% 0;
  -webkit-box-shadow: 0px 3px 7px 0px rgba(50, 50, 50, 0.5);
  -moz-box-shadow: 0px 3px 7px 0px rgba(50, 50, 50, 0.5);
  box-shadow: 0px 3px 7px 0px rgba(50, 50, 50, 0.5);
}

.sobre-titulo {
  font-size: 2.2em;
  margin-bottom: 2%;
  padding: 1% 0;
  font-weight: bold;
  text-align: center;
  -webkit-box-shadow: 0px -3px 7px 0px rgba(50, 50, 50, 0.5);
  -moz-box-shadow: 0px -3px 7px 0px rgba(50, 50, 50, 0.5);
  box-shadow: 0px -3px 7px 0px rgba(50, 50, 50, 0.5);
}

.sobre-texto {
  font-size: 1.5em;
  text-align: justify;
  padding: 3% 5% 3% 5%;
}

.sobre-image {
  margin-left: 5%;
  max-width: 1980px;
  width: 90%;
}

.sobre-lista {
  padding: 1em 5vw 0 5vw;
  font-size: 0.8em;
}

.sobre-tabela {
  margin: 0 auto;
  padding: 50px 0;
  text-align: center;
}

.sobre-titulo2 {
  font-size: 1.75em;
  text-align: center;
}

.sobre-tabela p {
  font-size: 1.1em;
  text-align: justify;
  padding: 5% 5% 3% 5%;
}

.sobre-tabela li {
  display: inline-block;
  text-align: center;
  width: 25%;
  vertical-align: top;
  margin: 0 1.5%;
  padding: 30px 20px;
}

@media (max-width: 1020px) {
  .sobre-tabela li {
    width: 90%;
  }
}
