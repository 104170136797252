.conteudo {
  background-color: white;
}

:root {
  --primaria: #58a4b0;
  --secundaria: #373f51;
  --secundaria-ativa: #cccccc;
  --fundo: #ffffff;
  --fundo-detalhes: #ececec;
}
